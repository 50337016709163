<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.brands">
			<template #title>Brands</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'BrandsNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>

			<v-data-table :headers="headers" :items="brands" item-key="id" :search="search" @click:row="editItem" :item-class="() => 'hover-pointer'">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "BrandsIndex",
	computed:{
		brands: sync('brands/brands')
	},
	data: () => ({
		search: '',
		headers:[
			{
				text: 'Name',
				align: 'start',
				sortable: true,
				value: 'name',
			},
			{
				text: '',
				align: 'end',
				sortable: false,
				value: 'actions',
			},
		]
	}),
	beforeMount(){
		this.init()
	},
	methods:{
		init(){
			this.getBrands()
		},
		editItem(item){
			this.$router.push({name:'BrandsEdit', params: {id: item.id}})
		},
		getBrands(){
			this.$store.set('brands/getBrands!', null)
		},
		filterOnlyCapsText (value, search, item) {
			return value != null &&
				search != null &&
				typeof value === 'string' &&
				value.toString().toLocaleUpperCase().indexOf(search) !== -1
		},
	}
}
</script>